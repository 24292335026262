import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import type { GraphqlTypes as gt } from '@pafcloud/graphql-schema/types';
import { lowercase } from '@pafcloud/util';
import type { LoginMethod } from '../login-flow/useUpdateLoginCookieStates';

export type TransactionAccount = gt.TransactionAccount;
export type TransactionMethodProvider = gt.TransactionMethodProvider;
export type TransactionMethodType = gt.TransactionMethodType;

export type TransactionDirection = 'DEPOSIT' | 'WITHDRAWAL';
export type TransactionAccountVerificationStatus = 'REQUESTED' | 'NOT_REQUESTED' | 'IN_PROGRESS';

export type TransactionMethod = {
  gateway: gt.TransactionGateway;
  methodProvider: TransactionMethodProvider;
  methodType: gt.TransactionMethodType;
  minAmount: number;
  maxAmount: number;
  accounts: ReadonlyArray<gt.TransactionAccount>;
  canAddAccount: boolean;
};

export type TransactionAccountDefaultValues = {
  fullName: string | null;
  email: string | null;
};

export const canUseAccount = (account: TransactionAccount) => {
  return account.verificationStatus !== 'REQUESTED' && account.verificationStatus !== 'IN_PROGRESS';
};

export const canAutomaticallySelectAccount = (method: TransactionMethod, direction: TransactionDirection): boolean => {
  if ($buildEnv.market === 'latvia' && direction === 'WITHDRAWAL') {
    // Because of new changes in latvia, we can no longer autoselect accounts.
    // Backend will always only send one account, customer need to see it to know where the money is going.
    return false;
  }

  if (method.methodProvider == 'CARD') {
    // To ensure that the user sees the masked card credentials before getting into the iframe.
    return false;
  }
  if (method.accounts.length > 0 && method.canAddAccount) {
    // The user has multiple options, both selecting an account and creating a new account.
    return false;
  }
  return method.accounts.length === 1 && canUseAccount(method.accounts[0]);
};

export const canOnlyCreateNewAccount = (method: TransactionMethod): boolean => {
  return method.accounts.length === 0 && method.canAddAccount;
};

export const getLoginMethod = (method?: TransactionMethod): LoginMethod => {
  switch (method?.methodProvider) {
    case 'BRITE':
      return 'brite';
    case 'TRUSTLY':
      return 'trustly';
    case 'SWISH':
      return 'signicat';
    default:
      return 'unknown';
  }
};

// TODO: Update the translation strings to avoid having this map
export const translationMethodProviderMap = {
  AKTIA: 'Aktia',
  ALANDSBANKEN: 'Alandsbanken',
  APLAUZ: 'Aplauz',
  APPLEPAY: 'ApplePay',
  BANK: 'Bank',
  BANKIBAN: 'Bank',
  BIZUM: 'Bizum',
  BRITE: 'Brite',
  CARD: 'Card',
  DANSKEBANK: 'Danskebank',
  HANDELSBANKEN: 'Handelsbanken',
  MOBILEPAY: 'MobilePay',
  MUCHBETTER: 'MuchBetter',
  NETELLER: 'Neteller',
  NORDEA: 'Nordea',
  OMASAASTOPANKKI: 'Omasaastopankki',
  OP: 'OP',
  OPCORPIBAN: 'OpCorpIBAN',
  PAYPAL: 'PayPal',
  PAYSAFECARD: 'PaySafeCard',
  POPPANKKI: 'PopPankki',
  SAASTOPANKKI: 'Saastopankki',
  SIIRTO: 'Siirto',
  SKRILL: 'Skrill',
  SPANKKI: 'Spankki',
  SWISH: 'Swish',
  TRUSTLY: 'Trustly',
  UNKNOWN: 'Unknown',
  CITADELE: 'Citadele',
  COOP: 'Coop',
  LHV: 'LHV',
  LUMINOR: 'Luminor',
  SEB: 'SEB',
  SWEDBANK: 'Swedbank',
  SWEDBANKEXPRESS: 'SwedbankExpress',
  NEOPAY: 'Neopay',
  POSTFINANCE_CARD: 'PostFinanceCard',
  POSTFINANCE_E_FINANCE: 'PostFinanceEFinance',
  POSTFINANCE_PAY: 'PostFinance Pay',
  TWINT: 'Twint',
  YAPEAL: 'Yapeal',
  REVOLUT: 'Revolut',
  PAYSERA: 'Paysera',
  WISE: 'Wise',
  WEBREDIRECT_GENERIC: 'GenericWebredirect',
} as const satisfies Record<TransactionMethodProvider, string>;

// TODO: Update the translation strings to avoid having this map
export const translationMethodTypeMap = {
  BANK: 'Bank',
  MOBILE: 'Mobile',
  EWALLET: 'EWallet',
  CARD: 'Card',
  WEBREDIRECT: 'Webredirect',
  UNKNOWN: 'Unknown',
  BANKIBAN: 'Bank',
} as const satisfies Record<TransactionMethodType, string>;

export const getTransactionMethodLogoUrl = (methodProvider: TransactionMethod['methodProvider']) => {
  // Dark themes should prefer lighter logos for contrast.
  const isDarkTheme =
    $buildEnv.theme === 'dreams' ||
    $buildEnv.theme === 'golden' ||
    $buildEnv.theme === 'goldenV2' ||
    $buildEnv.theme === 'momentum' ||
    $buildEnv.theme === 'momentum-neon';

  const transactionMethodLogos: Record<Exclude<TransactionMethodProvider, 'UNKNOWN'>, string> = {
    ALANDSBANKEN: 'alandsbanken.svg',
    AKTIA: 'aktia.svg',
    APLAUZ: 'aplauz.svg',
    APPLEPAY: isDarkTheme ? 'applepay-white.svg' : 'applepay.svg',
    BANK: isDarkTheme ? 'bank-white.svg' : 'bank.svg',
    BIZUM: 'bizum.svg',
    BRITE: isDarkTheme ? 'brite-white.svg' : 'brite.svg',
    DANSKEBANK: 'danskebank.svg',
    TRUSTLY: isDarkTheme ? 'trustly-white.svg' : 'trustly.svg',
    SAASTOPANKKI: 'saastopankki.svg',
    SWISH: isDarkTheme ? 'swish-white.svg' : 'swish.svg',
    SKRILL: isDarkTheme ? 'skrill-white.svg' : 'skrill.svg',
    NETELLER: 'neteller.svg',
    NORDEA: 'nordea.svg',
    OP: 'op.svg',
    CARD: isDarkTheme ? 'card-white.svg' : 'card.svg',
    SIIRTO: 'siirto.svg',
    MOBILEPAY: 'mobilepay.svg',
    HANDELSBANKEN: 'handelsbanken_glue.svg',
    OMASAASTOPANKKI: 'omasp_glue.svg',
    OPCORPIBAN: 'bank-list-finland.svg',
    BANKIBAN: isDarkTheme ? 'bank-white.svg' : 'bank.svg',
    PAYPAL: isDarkTheme ? 'paypal-white.png' : 'paypal.png',
    POPPANKKI: 'pop_glue.svg',
    PAYSAFECARD: isDarkTheme ? 'paysafecard-white.svg' : 'paysafecard.svg',
    SPANKKI: 'sbank.svg',
    MUCHBETTER: 'muchbetter.svg',
    CITADELE: 'citadele.svg',
    COOP: isDarkTheme ? 'coop-white.svg' : 'coop.svg',
    LHV: isDarkTheme ? 'lhv-white.svg' : 'lhv.svg',
    LUMINOR: isDarkTheme ? 'luminor-white.svg' : 'luminor.svg',
    SEB: isDarkTheme ? 'seb-white.svg' : 'seb.svg',
    SWEDBANK: 'swedbank.svg',
    SWEDBANKEXPRESS: 'swedbank-express.svg',
    NEOPAY: 'neopay.svg',
    POSTFINANCE_CARD: 'postfinance.svg',
    POSTFINANCE_E_FINANCE: 'postfinance.svg',
    POSTFINANCE_PAY: 'postfinancepay.svg',
    TWINT: 'twint.svg',
    YAPEAL: 'yapeal.svg',
    WEBREDIRECT_GENERIC: 'webredirect_generic.png',
    REVOLUT: isDarkTheme ? 'revolut-white.svg' : 'revolut.svg',
    PAYSERA: 'paysera.svg',
    WISE: 'wise.svg',
  };

  return methodProvider === 'UNKNOWN'
    ? undefined
    : `/static/images/payment-providers/${transactionMethodLogos[methodProvider]}`;
};

export function findTransactionMethod<T extends TransactionMethod>(
  methods: readonly T[],
  methodProvider: string | undefined,
) {
  if (methodProvider == null) {
    return undefined;
  }

  return methods.find((m) => lowercase(m.methodProvider) === lowercase(methodProvider));
}
