import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { HeroColors } from '../hero-colors';
import type { HeroDepositForm_contentBlock$key } from './__generated__/HeroDepositForm_contentBlock.graphql';
import { HeroDepositToPlayForm } from './HeroDepositToPlayForm';

const contentBlockFragment = graphql`
  fragment HeroDepositForm_contentBlock on HeroDepositFormBlock {
    buttonText
  }
`;

export const DepositFormContainer = styled.div({
  '--quick-button-text': HeroColors.DepositForm.QuickButtons,
  '--quick-button-text--hover': HeroColors.DepositForm.QuickButtonsHover,

  margin: '24px auto auto',
  maxWidth: 320,
});

type HeroDepositFormComponentProps = {
  contentBlock: HeroDepositForm_contentBlock$key;
  onHeroClick: () => void;
};

const HeroDepositForm: FC<HeroDepositFormComponentProps> = ({ contentBlock, onHeroClick }) => {
  const { buttonText } = useFragment(contentBlockFragment, contentBlock);
  return (
    <DepositFormContainer>
      <HeroDepositToPlayForm submitButtonText={buttonText} onHeroClick={onHeroClick} />
    </DepositFormContainer>
  );
};

export default HeroDepositForm;
