/**
 * @generated SignedSource<<ca23d91fc3f65b324351595cb30fd382>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeroDepositForm_contentBlock$data = {
  readonly buttonText: string;
  readonly " $fragmentType": "HeroDepositForm_contentBlock";
};
export type HeroDepositForm_contentBlock$key = {
  readonly " $data"?: HeroDepositForm_contentBlock$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroDepositForm_contentBlock">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroDepositForm_contentBlock",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonText",
      "storageKey": null
    }
  ],
  "type": "HeroDepositFormBlock",
  "abstractKey": null
};

(node as any).hash = "76d8541f2745e3c6ce4507fc8a390e62";

export default node;
