import styled from '@emotion/styled';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from '@pafcloud/i18n';
import { getCurrencyCode, getCurrencySymbol } from '@pafcloud/locale';
import { ButtonLink } from '@pafcloud/base-components';
import { FormInput, FormInputSuffix } from '@pafcloud/form-components';
import { Breakpoint } from '@pafcloud/style';
import { matchesMediaQuery } from '@pafcloud/react-hook-utils';
import { useFlowRouter } from '@pafcloud/flow-router';
import { translationMethodProviderMap } from '../../transaction';
import { getDepositFormConfig } from '../../transaction/deposit/DepositFormConfig';
import { QuickButtons } from '../../transaction/deposit/QuickButtons';

const InputContainer = styled.div({
  textAlign: 'center',
  margin: '16px auto',
  [Breakpoint.TabletOrLarger]: {
    maxWidth: '400px',
  },
});

const MethodsContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  gap: 16,
  margin: '24px auto',
  [Breakpoint.TabletOrLarger]: {
    marginTop: 48,
  },
});

const MethodButton = styled(ButtonLink)({
  minWidth: 120,
  padding: '8px 16px',
});

const Image = styled.img({
  minWidth: '100%',
});

type HeroPayAndPlayDepositToPlayFormProps = {
  onHeroClick: () => void;
};

const PAY_AND_PLAY_METHODS = ['SWISH', 'TRUSTLY'] as const;

export const HeroPayAndPlayDepositToPlayForm: FC<HeroPayAndPlayDepositToPlayFormProps> = ({ onHeroClick }) => {
  const { t } = useTranslation(['deposit', 'transaction-methods']);
  const formConfig = getDepositFormConfig();
  const [amount, setAmount] = useState(formConfig.minDepositButtonValue);
  const { getFlowUrl, openFlow } = useFlowRouter();

  return (
    <>
      <QuickButtons
        onSelect={(value) => openFlow('deposit', { initialAmount: value })}
        depositLimit={formConfig.maxDepositButtonValue}
      />

      <InputContainer>
        <FormInput
          autoComplete="off"
          floatingLabel={false}
          type="number"
          step="any"
          min="0"
          label={t('choose-amount.label')}
          value={amount}
          onChange={(e) => setAmount(Number(e.currentTarget.value))}
          onClick={() => {
            if (matchesMediaQuery(Breakpoint.Phone)) {
              openFlow('deposit', { initialAmount: amount });
            }
          }}
        >
          <FormInputSuffix>{getCurrencySymbol(getCurrencyCode())}</FormInputSuffix>
        </FormInput>
      </InputContainer>

      <MethodsContainer>
        {PAY_AND_PLAY_METHODS.map((method) => {
          const methodName = t(`transaction-methods:method-provider.${translationMethodProviderMap[method]}`, {
            defaultValue: translationMethodProviderMap[method],
          });

          return (
            <MethodButton
              key={method}
              variant="primary"
              colorstyle="accent"
              size="large"
              type="button"
              ctaEffect
              onClick={onHeroClick}
              href={getFlowUrl('deposit', { initialAmount: amount, method })}
              aria-label={methodName}
            >
              <Image src={`/static/images/payment-providers/${method.toLowerCase()}-monochrome.svg`} alt={methodName} />
            </MethodButton>
          );
        })}
      </MethodsContainer>
    </>
  );
};
