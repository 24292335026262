import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { withBuildEnv } from '@pafcloud/base-components';
import { DepositToPlayForm } from '../../transaction/deposit';
import { HeroPayAndPlayDepositToPlayForm } from './HeroPayAndPlayDepositToPlayForm';

export const HeroDepositToPlayForm = withBuildEnv(() => {
  if (
    $buildEnv.site === 'noaccountcasino.se' ||
    $buildEnv.site === 'noaccountbet.se' ||
    $buildEnv.site === 'prankcasino.se' ||
    $buildEnv.site === 'speedybet.com' ||
    $buildEnv.site === 'speedycasino.com'
  ) {
    return HeroPayAndPlayDepositToPlayForm;
  }
  return DepositToPlayForm;
});
